* {
  box-sizing: border-box;
  /* overscroll-behavior: contain; */
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  /* scroll-behavior: ; */
}

body {
  background: white;
  font-family: sans-serif;
  font-size: min(12vw, 86px);
  line-height: 0.75;
  /* -webkit-overflow-scrolling: touch; */
}

h1 {
  margin: 0;
}

::-webkit-scrollbar {
  display: none;
}
canvas + div {
  mix-blend-mode: multiply;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.bounce-animation {
  animation: bounces 0.7s;
  transition: all 1s;
}

@keyframes bounces {
  0% {
    transform: translateY(25%);
    transition: all 1s;
    animation-timing-function: cubic-bezier(0.8, 0, 0.9, 0.9);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    transition: all 1s;
    animation-timing-function: cubic-bezier(0.8, 0, 0.9, 0.9);
    opacity: 1;
  }
}

.fadeout {
  animation: fadeout 2s;
}
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation: fadeIn 2s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* .main_menu {
  opacity: 0;
  pointer-events: none;
  filter: blur(30px);
} */

.blur-animate {
  animation-name: blur;
  animation-duration: 800ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-direction: alternate;
}
.fadeout-animate {
  animation-name: fadeoutA;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

@keyframes blur {
  0% {
    opacity: 0;
    filter: blur(30px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
    pointer-events: all;
  }
}

@keyframes fadeoutA {
  0% {
    opacity: 1;
    filter: blur(0px);
    pointer-events: all;
  }
  100% {
    opacity: 0;
    filter: blur(30px);
    pointer-events: none;
  }
}

.fadeout-animate--1 {
  animation-name: fadeoutBB;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
  animation-iteration-count: 1;
  animation-direction: alternate;
  animation-duration: 1s;
}

@keyframes fadeoutBB {
  0% {
    opacity: 1;
    filter: blur(0);
    pointer-events: none;
  }
  100% {
    opacity: 0;
    filter: blur(30px);
    pointer-events: none;
  }
}

.imageee {
  background-color: hsla(0, 0%, 100%, 1);
  background-image: radial-gradient(
      at 0% 20%,
      hsla(275, 100%, 78%, 1) 0px,
      transparent 50%
    ),
    radial-gradient(at 2% 65%, hsla(144, 100%, 85%, 1) 0px, transparent 50%),
    radial-gradient(at 98% 36%, hsla(144, 100%, 85%, 1) 0px, transparent 50%),
    radial-gradient(at 100% 87%, hsla(204, 100%, 84%, 1) 0px, transparent 50%);
}
